import React, { useState, useEffect } from "react";
import { Nav } from "react-bootstrap";

import { Link } from "gatsby";
import "antd/dist/antd.css";
import { Menu } from "antd";

const { SubMenu } = Menu;

const Sider = (props) => {
  const isParentPage = (currentLink) => {
    if (
      props.parentPage.slug.current === props?.slug?.current ||
      props?.link ||
      props?.route
    ) {
      return `/${currentLink}`;
    } else {
      return `/${props.parentPage.slug.current}/${currentLink}`;
    }
  };
  const LevelThree = ({ item3 }) => {
    if (item3) {
      const mapper = item3.map((invidivualItem3) => {
        return (
          <li>
            <Link
              to={isParentPage(invidivualItem3.landingPageRoute.slug.current)}
              className="linkBox"
              activeClassName="active"
            >
              {invidivualItem3.title}
            </Link>
            {invidivualItem3.subLink && invidivualItem3.subLink.length > 0 && (
              <ul>
                {" "}
                <LevelThree item3={invidivualItem3.sublink} />
              </ul>
            )}
          </li>
        );
      });

      return mapper;
    } else {
      return null;
    }
  };

  const LevelTwo = ({ item2 }) => {
    const mapper = item2.map((invidivualItem2) => {
      return (
        <li>
          <Link
            to={isParentPage(invidivualItem2.landingPageRoute.slug.current)}
            className="linkBox"
            activeClassName="active"
          >
            {invidivualItem2.title}
          </Link>
          {invidivualItem2?.subLink?.length > 0 && (
            <ul className="menuList">
              {" "}
              <LevelThree item3={invidivualItem2.subLink} />
            </ul>
          )}
        </li>
      );
    });
    return mapper;
  };

  const LevelOne = () => {
    const mapper =
      props?.props?.items &&
      props.props.items.map((item1) => (
        <li>
          <Link
            to={isParentPage(item1.landingPageRoute.slug.current)}
            className="linkBox"
            activeClassName="active"
          >
            {item1.title}
          </Link>

          {item1?.subLink?.length > 0 && (
            <ul className="menuList">
              {" "}
              <LevelTwo item2={item1.subLink} />
            </ul>
          )}
        </li>
      ));
    return mapper;
  };

  return (
    <ul className="menuList">
      <LevelOne />
    </ul>
  );
};

export default Sider;

import React from "react";
import imageUrlBuilder from "@sanity/image-url";
import client from "./sanityClient";
import PdfIcon from "../../images/pdf.png";
import PptIcon from "../../images/ppt.png";
import XlsIcon from "../../images/xls.png";
import DocIcon from "../../images/doc.png";
import ZipIcon from "../../images/zip.png";

const builder = imageUrlBuilder(client);

function Pdf(props) {
  return (
    <div className="pdf">
      <a href={props.pdf.asset.url}>
        {{
          pdf: <img src={PdfIcon} alt="PDF icon" />,
          ppt: <img src={PptIcon} alt="PPT icon" />,
          pptx: <img src={PptIcon} alt="PPT icon" />,
          xls: <img src={XlsIcon} alt="XLS icon" />,
          xlsx: <img src={XlsIcon} alt="XLS icon" />,
          doc: <img src={DocIcon} alt="DOC icon" />,
          docx: <img src={DocIcon} alt="DOC icon" />,
          zip: <img src={ZipIcon} alt="ZIP icon" />,
        }[props.pdf.asset.extension] || (
          <img src={"/static/icons/file.png"} alt="FILE logo" />
        )}
      </a>
      <p>
        <a href={props.pdf.asset.url}>
          <span className="fileName">
            {props.pdf.Filename
              ? props.pdf.Filename
              : props.pdf.asset.originalFilename}
          </span>{" "}
        </a>
      </p>
    </div>
  );
}
export default Pdf;

import React, { useState } from "react";
import { graphql } from "gatsby";

import { Container, Row, Col } from "react-bootstrap";

import Hero from "../components/cmsComponents/hero";
import LinkBoxes from "../components/cmsComponents/linkBoxes";
import BlockContent from "../components/cmsComponents/blockContent";
import LinkLister from "../components/cmsComponents/linkLister";
import SideMenu from "../components/sideMenu/sideMenu";
import FormBuilder from "../components/cmsComponents/formBuilder/formBuilder";
import { window, document, exists } from "browser-monads";

import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../components/layout";

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    sanityPage(id: { eq: $id }) {
      ...PageInfo
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      openGraph {
        title
        description
        image {
          ...SanityImage
        }
      }
      image {
        asset {
          url
        }
      }
    }
  }
`;

const Page = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const page = data.sanityPage || data.page;

  const content = (page._rawContent || [])
    .filter((c) => !c.disabled)
    .map((c, i) => {
      let el = null;
      switch (c._type) {
        case "hero":
          el = <Hero key={c._key} {...c} indexPage={props.indexPage} />;
          break;
        case "linkBoxes":
          el = <LinkBoxes key={c._key} {...c} />;
          break;
        case "blockText":
          el = <BlockContent key={c._key} {...c} />;
          break;
        case "linkList":
          el = <LinkLister key={c._key} {...c} />;
          break;
        case "formBuilder":
          el = <FormBuilder key={c._key} {...c} />;
          break;
        default:
          el = null;
      }
      return el;
    });

  // const menuItems = page.navMenu && (page.navMenu.items || []);
  // const pageTitle = data.route && !data.route.useSiteTitle && page.title;
  // const parent2 = (data.navMenu && page.navMenu.parentPage) || null;

  // Checks if a page has a parent page
  const parent = () => {
    if (
      page.navMenu &&
      page.navMenu.parentPage &&
      page.navMenu.parentPage.slug.current
    ) {
      return page.navMenu.parentPage;
    } else {
      return null;
    }
  };

  // Gives the index page a full width fluid bootstrap container and all other pages a regular bootstrap container
  const PageContainer = ({ children }) => {
    if (props.indexPage) {
      return (
        <Container fluid className="__p-0">
          {children}
        </Container>
      );
    } else {
      return <Container className="__p-0"> {children}</Container>;
    }
  };

  const openGraphData =
    props.data?.sanityPage?.openGraph || props.data.openGraph;

  // const WindowTester = () => {
  //   if (typeof window !== `undefined`) {
  //     return (
  //       <Col md={4} lg={4} className="d-none d-md-block">
  //         <nav aria-label="local" className="local-navigation">
  //           <SideMenu
  //             props={page.navMenu}
  //             parentPage={parent()}
  //             location={props.location}
  //           />
  //         </nav>
  //       </Col>
  //     );
  //   } else {
  //     return null;
  //   }
  // };

  return (
    <Layout parentPage={parent()}>
      <SEO title={page.title} openGraph={openGraphData} />
      <PageContainer props={props}>
        <Row>
          {/* Loads side menu if a page is not the index page */}
          {/* {page.navMenu && !props.indexPage && <WindowTester />} */}
          {page.navMenu && !props.indexPage && (
            <>
              {" "}
              {/* <WindowTester /> */}
              <Col md={4} lg={4} className="d-none d-md-block">
                <nav aria-label="local navigation" className="local-navigation">
                  <SideMenu
                    props={page.navMenu}
                    parentPage={parent()}
                    location={props.location}
                  />
                </nav>
              </Col>
            </>
          )}

          <Col className="p-0">
            <main>{content}</main>
          </Col>
        </Row>
      </PageContainer>
    </Layout>
  );
};

export default Page;

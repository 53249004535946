import React from "react";
import Errors from "../../errors";

const errorMessages = ({ props, name, error }) => {
  const ErrorMessagesText = ({ type }) => {
    return <p>{props.error[type]}</p>;
  };

  const MessageFinder = () => {
    // TODO add regex validation of email
    if (error && error.type && error.type === "required") {
      return <ErrorMessagesText type="missingEntry" />;
    } else if (error && error.type && error.type === "maxLength") {
      return <ErrorMessagesText type="maximumError" />;
    } else if (error && error.type && error.type === "minLength") {
      return <ErrorMessagesText type="minimumError" />;
    } else {
      return null;
    }
  };

  return <MessageFinder />;
};

export default errorMessages;

import React from "react";
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import {
  useNetlifyForm,
  NetlifyFormProvider,
  NetlifyFormComponent,
  Honeypot,
} from "react-netlify-forms";

import InputField from "./inputField";
import TextBox from "./textBox";
// https://pyrax.github.io/react-netlify-forms/examples/react-hook-form/

const Content = ({ props, register, errors }) => {
  let el = null;
  switch (props._type) {
    case "textField":
      el = (
        <InputField
          key={props._key}
          props={props}
          register={register}
          errors={errors}
          type="text"
        />
      );
      break;
    case "emailField":
      el = (
        <InputField
          key={props._key}
          props={props}
          register={register}
          errors={errors}
          type="email"
        />
      );
      break;
    case "numberField":
      el = (
        <InputField
          key={props._key}
          props={props}
          register={register}
          errors={errors}
          type="number"
        />
      );
      break;
    case "textBox":
      el = (
        <TextBox
          key={props._key}
          props={props}
          register={register}
          errors={errors}
        />
      );
      break;
    default:
      el = null;
  }
  return el;
};

const FormLooper = ({ props, register, errors }) => {
  const looper = props.content.map((formField) => (
    <Content
      props={formField}
      register={register}
      key={formField._key}
      errors={errors}
    />
  ));
  return looper;
};

const FormBuilder = (props) => {
  const { register, handleSubmit, watch, errors } = useForm({
    mode: "onChange",
  });
  const onSubmit = (data) => netlify.handleSubmit(null, data);

  const formName = (formName) => {
    if (formName && formName.formTitle) {
      return formName.formTitle;
    } else {
      return "Untitled form";
    }
  };

  const netlify = useNetlifyForm({
    name: formName(props),
    action: "",
    honeypotName: "bot-field",
    onSuccess: (response, context) => {

    },
  });

  return (
    <>
      {props && props.content && (
        <NetlifyFormProvider {...netlify}>
          <NetlifyFormComponent onSubmit={handleSubmit(onSubmit)}>
            <Honeypot />
            {netlify.success && <p>Thanks for contacting us!</p>}
            <FormLooper props={props} register={register} errors={errors} />
            <input type="submit" />
          </NetlifyFormComponent>
        </NetlifyFormProvider>
      )}
    </>
  );
};

export default FormBuilder;

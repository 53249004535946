import React from "react";
import DefaultBlockContent from "../sanity/defaultBlockContent";
import { getFluidGatsbyImage } from "gatsby-source-sanity";
import { Container, Row, Col } from "react-bootstrap";
import FeaturedPages from "./linkBoxLister";

import clientConfig from "../sanity/sanityClient";

const infoBoxes = (props, key) => {
  return (
    <Container>
      {props.title && <h2>{props.title}</h2>}
      <Row>
        <FeaturedPages pageData={props.linkedPages} />
      </Row>
    </Container>
  );
};

export default infoBoxes;
